import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/postores',
        name: 'bidders-list',
        component: () => import('@/views/modules/bidders/bidders-list/BiddersList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Postores',
            breadcrumb: [{
                text: 'Lista de postores',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/postores/crear',
        name: 'bidders-create',
        component: () => import ('@/views/modules/bidders/bidders-store/BiddersStore.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Postores',
            breadcrumb: [{
                text: 'Agregar postor',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/postores/:id/editar',
        name: 'bidders-edit',
        component: () => import ('@/views/modules/bidders/bidders-edit/BiddersEdit.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Postores',
			breadcrumb: [{
				text: 'Lista de postores', 
				to: {
					name: 'bidders-list',
				}
			},{
                text: 'Editar postor',
                active: true
            }],
            requiresAuth: true
        }
    }
]