export default [
    {
        path: '/no-autorizado',
        name: 'misc-not-authorized',
        component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: 'ACL'
        }
    },
    /* {
        path: '/en-mantenimiento',
        name: 'misc-under-maintenance',
        component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
        }
    } */
]