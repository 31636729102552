import useJwt from '@/auth/jwt/useJwt';
import { jwtDecode } from 'jwt-decode';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style

export const checkUserRole = () => {
	const token = localStorage.getItem('accessToken')
	if (!token) return

	const decodedToken = jwtDecode(token)
	return decodedToken.role
    /* const userPermissions = decodedToken.permissions; */
}

export const getUserRoles = () => {
	const userRole = checkUserRole()

	if (!userRole) {
		return {
			isAdmin: false,
			isBidder: false,
			isManager: false,
			isSuperAdmin: false,
			isAll: false
		  }
	}

	const roles = {
		isAdmin: userRole === 'admin',
		isBidder: userRole === 'bidder',
		isManager: userRole === 'manager',
		isSuperAdmin: userRole === 'superadmin'
	}
	
	return {
		...roles,
		isAll: roles.isAdmin || roles.isBidder || roles.isManager || roles.isSuperAdmin
	}
}

export const isUserLoggedIn = async () => {

    try {

        let isLogged = false;

        const accessToken = localStorage.getItem('accessToken');
        const userData = localStorage.getItem('userData');

        if (accessToken) {
            const response = await useJwt.user();
            if (response.data.success) isLogged = true;
        }

        if (isLogged && userData) isLogged = true;

        if (!isLogged) {
            localStorage.removeItem('userData');
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
        }

        return isLogged;

    } catch (err) {

        localStorage.removeItem('userData');
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

        return false;

    }

};

export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

export const checkBeforeEnter = (requiredRoles, to, from, next) => {

	const userRoles = getUserRoles()

	const hasAccess = requiredRoles.some(role => userRoles[role])

    if (!hasAccess) {
		return next({ name: 'misc-not-authorized' });
	}
	
	next();

};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = () => {
	const { isAll } = getUserRoles()

	if (isAll) {
		return { name: 'home' }
	}

    return { name: 'login' }
};