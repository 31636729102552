import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/visitas',
        name: 'visits-list',
        component: () => import('@/views/modules/visits/visits-list/VisitsList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'visits',
            pageTitle: 'Visitas',
            breadcrumb: [{
                text: 'Lista de visitas',
                active: true
            }],
            requiresAuth: true
        }
    }
]